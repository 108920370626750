export const RaceRecords = {
  Marathon: [
    {
      date: '2010/11/21',
      event: '2010 Williams Route 66 Marathon',
      location: 'Tulsa, OK, USA',
      result: '04:02:20',
      official: true,
    },
    {
      date: '2011/5/1',
      event: '2011 Oklahoma City Memorial Marathon',
      location: 'Oklahoma City, OK, USA',
      result: '04:06:51',
      official: true,
    },
    {
      date: '2011/10/9',
      event: "2011 Rock 'n' Roll Denver Marathon",
      location: 'Denver, CO, USA',
      result: '04:21:09',
      official: true,
    },
    {
      date: '2014/3/30',
      event: '2014 八卦山臺地馬拉松',
      location: '台灣彰化',
      result: '04:45:52',
      official: false,
    },
    {
      date: '2014/4/20',
      event: '2014 第二屆集集盃綠色隧道馬拉松',
      location: '台灣南投',
      result: '04:37:50',
      official: false,
    },
    {
      date: '2014/5/4',
      event: '2014北港媽祖盃馬拉松',
      location: '台灣雲林',
      result: '04:29:25',
      official: false,
    },
    {
      date: '2014/11/9',
      event: '2014 臺灣米倉田中馬拉松',
      location: '台灣彰化',
      result: '04:44:12',
      official: false,
    },
    {
      date: '2015/3/22',
      event: '2015 新北市萬金石馬拉松',
      location: '台灣新北',
      result: '03:58:21',
      official: true,
    },
    {
      date: '2015/7/5',
      event: '2015 日落馬拉松',
      location: '新加坡',
      result: '04:52:16',
      official: false,
    },
    {
      date: '2015/11/8',
      event: '2015 臺灣米倉田中馬拉松',
      location: '台灣彰化',
      result: '05:56:56',
      official: false,
    },
    {
      date: '2015/12/6',
      event: '2015 Standard Chattered Marathon Singapore',
      location: 'Singapore',
      result: '05:54:17',
      official: true,
    },
    {
      date: '2016/12/4',
      event: '2016 Standard Chattered Singapore Marathon',
      location: 'Singapore',
      result: '05:53:41',
      official: true,
    },
    {
      date: '2017/12/3',
      event: '2017 Standard Chattered Singapore Marathon',
      location: 'Singapore',
      result: '04:49:34',
      official: true,
    },
    {
      date: '2018/11/11',
      event: '2018 臺灣米倉田中馬拉松',
      location: '台灣彰化',
      result: '04:21:41',
      official: false,
    },
    {
      date: '2018/12/9',
      event: '2018 Standard Chattered Singapore Marathon',
      location: 'Singapore',
      result: '04:37:40',
      official: true,
    },
    {
      date: '2019/11/10',
      event: '2018 臺灣米倉田中馬拉松',
      location: '台灣彰化',
      result: '03:57:34',
      official: false,
    },
    {
      date: '2022/12/4',
      event: '2022 Standard Chattered Singapore Marathon',
      location: 'Singapore',
      result: '03:51:51',
      official: true,
    },
    {
      date: '2023/12/17',
      event: '2023 Taipei Marathon',
      location: 'Taipei',
      result: '03:35:36',
      official: true,
    },
  ],
  'Half Marathon': [
    {
      date: '2010/4/25',
      event: 'Oklahoma City Memorial Marathon',
      location: 'Oklahoma City, OK, USA',
      result: '01:52:31',
      official: true,
    },
    {
      date: '2014/10/26',
      event: '2014 永豐紅酒半程馬拉松變裝嘉年華',
      location: 'Singapore',
      result: '02:08:32',
      official: false,
    },
    {
      date: '2017/4/30',
      event: 'Income Ecorun',
      location: 'Singapore',
      result: '01:52:12',
      official: false,
    },
    {
      date: '2018/4/29',
      event: 'Income Ecorun',
      location: 'Singapore',
      result: '02:20:26',
      official: false,
    },
    {
      date: '2019/4/28',
      event: 'Income Ecorun',
      location: 'Singapore',
      result: '01:53:15',
      official: false,
    },
    {
      date: '2019/6/1',
      event: 'Osim Sundown Marathon',
      location: 'Singapore',
      result: '01:53:24',
      official: false,
    },
    {
      date: '2023/4/16',
      event: '2XU Compression Run',
      location: 'Singapore',
      result: '01:44:19',
      official: false,
    },
  ],
  '50K': [
    {
      date: '2011/2/27',
      event: '2011 The Cowtown Marathon',
      location: 'Fort Worth, TX, USA',
      result: '05:13:07',
      official: true,
    },
    {
      date: '2012/2/26',
      event: '2012 The Cowtown Marathon',
      location: 'Fort Worth, TX, USA',
      result: '05:49:21',
      official: true,
    },
    {
      date: '2013/2/24',
      event: '2013 The Cowtown Marathon',
      location: 'Fort Worth, TX, USA',
      result: '05:44:49',
      official: true,
    },
  ],
  '5K': [
    {
      date: '2010/08/28',
      event: 'Healthy Sooners 5k Fun Run 2010',
      location: 'Norman, OK, USA',
      result: '00:23:30',
      official: true,
    },
    {
      date: '2011/09/17',
      event: 'Healthy Sooners 5k Fun Run 2011',
      location: 'Norman, OK, USA',
      result: '00:23:26',
      official: true,
    },
    {
      date: '2012/09/15',
      event: 'Healthy Sooners 5k Fun Run 2012',
      location: 'Norman, OK, USA',
      result: '00:22:27',
      official: true,
    },
  ],
  Parkrun: [
    // assume Parkruns are official by Parkrun
    {
      date: '2019/11/23',
      event: 'West Coast Park Parkrun #158',
      location: 'Singapore',
      result: '00:23:44',
      official: true,
    },
    {
      date: '2019/12/07',
      event: 'West Coast Park Parkrun #160',
      location: 'Singapore',
      result: '00:22:33',
      official: true,
    },
    {
      date: '2020/01/04',
      event: 'West Coast Park Parkrun #165',
      location: 'Singapore',
      result: '00:22:40',
      official: true,
    },
    {
      date: '2020/02/01',
      event: 'West Coast Park Parkrun #169',
      location: 'Singapore',
      result: '00:22:45',
      official: true,
    },
    {
      date: '2022/05/07',
      event: 'West Coast Park Parkrun #172',
      location: 'Singapore',
      result: '00:22:45',
      official: true,
    },
    {
      date: '2022/05/14',
      event: 'West Coast Park Parkrun #173',
      location: 'Singapore',
      result: '00:22:43',
      official: true,
    },
    {
      date: '2022/06/11',
      event: 'West Coast Park Parkrun #177',
      location: 'Singapore',
      result: '00:21:58',
      official: true,
    },
    {
      date: '2022/07/09',
      event: 'West Coast Park Parkrun #181',
      location: 'Singapore',
      result: '00:24:52',
      official: true,
    },
    {
      date: '2022/08/06',
      event: 'West Coast Park Parkrun #185',
      location: 'Singapore',
      result: '00:22:51',
      official: true,
    },
    {
      date: '2022/10/01',
      event: 'West Coast Park Parkrun #194',
      location: 'Singapore',
      result: '00:21:55',
      official: true,
    },
    {
      date: '2023/01/14',
      event: 'West Coast Park Parkrun #209',
      location: 'Singapore',
      result: '00:23:53',
      official: true,
    },
    {
      date: '2023/03/11',
      event: 'West Coast Park Parkrun #217',
      location: 'Singapore',
      result: '00:23:02',
      official: true,
    },
    {
      date: '2023/04/22',
      event: 'West Coast Park Parkrun #223',
      location: 'Singapore',
      result: '00:22:18',
      official: true,
    },
  ],
};
