import { useEffect, useState } from 'react';

import { Button, Col, Form, Row } from 'react-bootstrap';

import { PageLayout } from '../../../components/PageLayout';
import {
  RigelExponentLookupTableDistances as Distances,
  RigelExponentLookupTableTargetDistances as TargetDistances,
} from '../../../data/constants';

import { FormWrapper } from '../../../assets/styles/FormWrapper.styles';

export const RiegelExponentLookup = () => {
  const [recentRace, setRecentRace] = useState(Distances[0]);
  const [recentRaceDuration, setRecentRaceDuration] = useState();
  const [targetRace, setTargetRace] = useState('kg');
  const [rigelExponent, setRigelExponent] = useState();

  const handleKeyDown = (event) => {
    // Allow only positive integers
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    if (/\D/.test(keyValue) && keyCode !== 8 && keyCode !== 9) {
      event.preventDefault();
    }
  };

  // to test if a var is a positive number: (typeof myVar === 'number' && myVar > 0)

  const handleOnClick = () => {
    // if (avgPower && weight && duration && distance) {
    //   setRe(
    //     ((distanceUnit === 'Mile' ? distance * MileToKM : distance) * 1000) /
    //       duration /
    //       (avgPower / (weightUnit === 'lb' ? weight * PoundToKG : weight)),
    //   );
    //   // console.log(`RE = ${re.toFixed(2)}`);
    // } else {
    //   // set error message
    //   console.log('Missing data.');
    // }
  };

  useEffect(() => {
    console.log(recentRace);
    console.log(targetRace);
  }, [recentRace, targetRace]);

  return (
    <>
      <PageLayout title="Riegel Exponent Lookup">
        {/* Add some descriptions here. */}
        Lookup <b>Riegel Exponent</b> used in race planning.
        <hr />
        <FormWrapper>
          <Form>
            <Row className="mb-3">
              <Form.Group as={Col} className="col-4">
                <Form.Label column="sm">
                  Recent Race Distance
                  <br />
                  (Choose One)
                </Form.Label>

                <Form.Select
                  onChange={(event) => setRecentRace(event.target.value)}
                >
                  {Distances.map((d) => (
                    <option key={`recent-race-option-${d}`}>{d}</option>
                  ))}
                </Form.Select>
              </Form.Group>
              <Form.Group as={Col} className="col-4">
                <Form.Label column="sm">
                  Recent Race Time
                  <br />
                  (in number of seconds)
                </Form.Label>
                <Form.Control
                  type="number"
                  min="0"
                  placeholder="seconds"
                  onKeyDown={handleKeyDown}
                  onChange={(event) =>
                    setRecentRaceDuration(event.target.value)
                  }
                />
              </Form.Group>
              <Form.Group as={Col} className="col-4">
                <Form.Label column="sm">
                  Target Race
                  <br />
                  (Choose One)
                </Form.Label>
                <br />
                <Form.Select
                  onChange={(event) => setTargetRace(event.target.value)}
                >
                  {TargetDistances.map((d) => (
                    <option key={`target-race-option-${d}`}>{d}</option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Row>

            <Row className="mb-3">
              {/* <Col></Col> */}
              <Col className="col-4">
                <Form.Text className="text-muted">
                  Data are only used locally on your computer, not sent to the
                  server.
                </Form.Text>
              </Col>
              <Col></Col>
              <Col className="col-4 my-3 d-flex justify-content-end">
                <Button variant="primary" onClick={handleOnClick}>
                  Lookup
                </Button>
              </Col>
            </Row>
          </Form>
        </FormWrapper>
        <hr />
        <h2>Results</h2>
        {rigelExponent ? (
          `Rigel Exponent: ${rigelExponent}`
        ) : (
          <>
            Please fill the form and click <b>Calculate</b> button.
          </>
        )}
        <hr />
        <h4>References:</h4>
        <ol className="page-quote">
          <li>
            <a
              href="https://www.facebook.com/groups/PalladinoPowerProject"
              target="_blank"
              rel="noreferrer"
            >
              Palladino Power Project
            </a>
          </li>
          <li>
            <a
              href="https://www.powerpacing.run/key-concepts/fatigue-factor/"
              target="_blank"
              rel="noreferrer"
            >
              What is a Fatigue Factor (i.e. Riegel Exponent)?
            </a>
          </li>
          <li>
            <a
              href="https://www.powerpacing.run/superpower-calculator/"
              target="_blank"
              rel="noreferrer"
            >
              [Guide] SuperPower Calculator for Sheets (SPCs)
            </a>
          </li>
          <li>
            <a
              href="http://f1r2a.com/SuperPower_Calculator"
              target="_blank"
              rel="noreferrer"
            >
              [File] SuperPower Calculator for Sheets (SPCs)
            </a>
          </li>
        </ol>
      </PageLayout>
    </>
  );
};
