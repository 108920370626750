import { Routes, Route } from 'react-router-dom';

import { Home } from '../../pages/Home';
import { RunningAndMe } from '../../pages/RunningAndMe';
import { RunningRecords } from '../../pages/RunningAndMe/RunningRecords';
import { RunningWithPower } from '../../pages/RunningWithPower';
import { CriticalPowerCalculator } from '../../pages/RunningWithPower/CriticalPowerCalculator';
import { RunningEffectivenessCalculator } from '../../pages/RunningWithPower/RunningEffectivenessCalculator';
import { RiegelExponentLookup } from '../../pages/RunningWithPower/RiegelExponentLookup';
// import { Apps } from '../../pages/Apps';
import { About } from '../../pages/About';
import { NotFound } from '../../pages/NotFound';

export const Content = () => (
  <Routes>
    <Route path="/" element={<Home />} />
    <Route path="/running-and-me" element={<RunningAndMe />} />
    {/* <Route path="/running-and-me/:id" element={<RunningAndMe />} /> */}
    <Route
      path="/running-and-me/running-records"
      element={<RunningRecords />}
    />
    <Route path="/running-with-power" element={<RunningWithPower />} />
    {/* <Route path="/running-with-power/:id" element={<RunningWithPower />} /> */}
    <Route
      path="/running-with-power/critical-power-calculator"
      element={<CriticalPowerCalculator />}
    />
    <Route
      path="running-with-power/running-effectiveness-calculator"
      element={<RunningEffectivenessCalculator />}
    />
    <Route
      path="running-with-power/riegel-exponent-lookup"
      element={<RiegelExponentLookup />}
    />

    {/* <Route path="/apps" element={<Apps />} /> */}
    <Route path="/about" element={<About />} />
    <Route path="/not-found" element={<NotFound />} />
    <Route path="*" element={<NotFound />} />
  </Routes>
);
