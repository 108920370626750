const PoundToKG = 0.453592;
const MileToKM = 1.60934;

const RigelExponentLookupTableDistances = [
  '5k',
  '10k',
  'Half Marathon',
  'Marathon',
];

const RigelExponentLookupTableTargetDistances = [
  '5k Max',
  '10k Max',
  'Half Marathon Max',
  'Marathon Max',
];

export {
  PoundToKG,
  MileToKM,
  RigelExponentLookupTableDistances,
  RigelExponentLookupTableTargetDistances,
};
