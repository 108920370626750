// import { useParams } from 'react-router-dom';
import { Button, Col, Row } from 'react-bootstrap';

import { PageLayout } from '../../components/PageLayout';
// import { CriticalPowerCalculator } from './CriticalPowerCalculator';

export const RunningWithPower = () => {
  // const { id } = useParams();

  // if (id === 'critical-power-calculator') return <CriticalPowerCalculator />;

  const handleGo = (url) => {
    window.open(url, '_self');
  };

  return (
    <PageLayout
      title="Running with Power"
      description="Something about running with power."
    >
      <Row>
        <Col className="col-12 col-sm-12 col-md-6 col-lg-4 col-lgxl-4 col-xxl-3">
          <h2>Critical Power Calculator</h2>
          <p>
            Calculate and visualize critical power and W' using two maximal
            efforts.
            <br />
            透過兩次力竭測試結果來計算並視覺化關鍵功率 CP 與 W'。
          </p>
          <p>
            <Button
              variant="outline-dark"
              onClick={() =>
                handleGo('running-with-power/critical-power-calculator')
              }
            >
              Go &raquo;
            </Button>
          </p>
        </Col>
        <Col className="col-12 col-sm-12 col-md-6 col-lg-4 col-lgxl-4 col-xxl-3">
          <h2>Running Effectiveness Calculator</h2>
          <p>
            Calculate Running Effectiveness.
            <br />
            計算跑步功率的效力，每單位功率帶給你的速率（每瓦/公斤 帶給你的
            每米/秒）。
          </p>
          <p>
            <Button
              variant="outline-dark"
              onClick={() =>
                handleGo('running-with-power/running-effectiveness-calculator')
              }
            >
              Go &raquo;
            </Button>
          </p>
        </Col>
        <Col className="col-12 col-sm-12 col-md-6 col-lg-4 col-lgxl-4 col-xxl-3">
          <h2>Riegel Exponent Lookup</h2>
          <p>
            Lookup your Riegel Exponent used in race planning.
            <br />
            查詢用來計算比賽功率的 Riegel Exponent。
          </p>
          <p>
            <Button
              variant="outline-dark"
              onClick={() =>
                handleGo('running-with-power/riegel-exponent-lookup')
              }
            >
              Go &raquo;
            </Button>
          </p>
        </Col>
      </Row>
      <br />
    </PageLayout>
  );
};
